import React from 'react'
import { Operation } from 'api/clientStatisticApi'

export const hasPerformanceSupport = () =>
  Boolean(window.performance && window.performance.mark)

export interface PerformanceProps {
  operation: Operation
}

export const startPostfix = 'Start'
export const endPostfix = 'End'

export const createStartMark = (operation: Operation) =>
  operation + startPostfix
