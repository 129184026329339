import React, { FC, useEffect, useState } from 'react'
import { Replace } from 'components/system/Replace'

export const ReplaceOnce: FC<{
  uid: string
  to: string
}> = ({ uid, to }) => {
  const [replaced, setReplaced] = useState(false)

  useEffect(() => {
    setReplaced(true)
  }, [])

  if (replaced) {
    return null
  }

  return <Replace uid={uid} to={to} />
}
