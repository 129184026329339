import { FC, useMemo } from 'react'
import {
  createStartMark,
  PerformanceProps,
} from 'components/system/statistic/Perfomance'

export const MeasurerStart: FC<PerformanceProps> = ({ operation }) => {
  useMemo(() => {
    window.performance.mark(createStartMark(operation))
  }, [])
  return null
}
