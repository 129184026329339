import React from 'react'
import { useRouteMatch } from 'react-router'
import { landingLayoutContextId } from '../layout/MambaLayout/Context'
import { isLoveMailru } from 'common/constants'
import { LandingSwitcher } from 'components/landing/LandingSwitcher'
import { CreateBaseUrl } from 'components/layout/MambaLayout/CreateContext'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import {
  appAgreementIndexPath,
  indexPath,
  registrationPath,
} from 'components/paths'
import { landingOperation } from 'api/clientStatisticApi'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { PerformanceStart } from 'components/system/statistic/PerfomanceStart'
import { LocaleSwitch } from 'components/system/LocaleSwitch'
import { useReducersInsert } from 'hooks/useReducersInsert'
import { stepRegistrationReducer } from 'reducers/registration/stepRegistrationReducer'
import { ReplaceOnce } from 'components/system/ReplaceOnce'
import { unauthorizedCaptchaPath } from 'components/page/Captcha/Captcha.paths'
import { CaptchaPageLoadable } from 'components/page/Captcha/CaptchaPageLoadable'
import { promoCodeRoutes } from 'components/page/PromoCode/promoCode.routes'
import { useRedesign } from 'hooks/useRedesign'

const LandingCommon = () => {
  useReducersInsert({
    stepRegistration: stepRegistrationReducer,
  })
  const match = useRouteMatch()
  const redesign = useRedesign()
  const { partnerId, loveMailruAuth, location } = useShallowEqualSelector(
    ({
      systemReducer: { partnerId },
      authorizationReducer: { loveMailruAuth },
      router: { location },
    }) => ({
      partnerId,
      loveMailruAuth,
      location,
    })
  )

  /**
   * Если партнер
   * loveMailru и пришел один из флагов,
   * src/common/reducers/authorizationReducer.ts: 231
   * То нужно отправить на дорегу.
   */
  if (match && partnerId) {
    return (
      <CreateBaseUrl
        contextId={landingLayoutContextId}
        baseUrl={match.url}
        basePath={match.path}
      >
        <PerformanceStart operation={landingOperation} />
        <LocaleSwitch
          matchUrl={match.url}
          routes={
            redesign
              ? [
                  {
                    path: unauthorizedCaptchaPath,
                    component: CaptchaPageLoadable,
                  },
                  ...promoCodeRoutes,
                  {
                    path: indexPath,
                    component: LandingSwitcher,
                    exact: false,
                  },
                ]
              : [
                  {
                    path: indexPath,
                    component: LandingSwitcher,
                    exact: false,
                  },
                ]
          }
        />
        {isLoveMailru(partnerId) &&
          loveMailruAuth &&
          !location.pathname.includes(appAgreementIndexPath) && (
            <ReplaceOnce
              to={mergeAllUrls(match.url, registrationPath)}
              uid="LandingCommon"
            />
          )}
      </CreateBaseUrl>
    )
  }

  return null
}

export default LandingCommon
