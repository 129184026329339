import React, { FC } from 'react'
import {
  hasPerformanceSupport,
  PerformanceProps,
} from 'components/system/statistic/Perfomance'
import { MeasurerStart } from 'components/system/statistic/PerfomanceMeasurerStart'

export const PerformanceStart: FC<PerformanceProps> = ({ operation }) => {
  if (process.env.browser) {
    if (hasPerformanceSupport()) {
      // console.log('start', operation)
      return <MeasurerStart operation={operation} />
    }
  }
  return null
}
