import { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  setStatusCodeAction,
  showNotFoundAction,
} from 'actions/system/routeAction'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { useLocation } from 'react-router'
import { useLayoutContext } from 'hooks/useLayoutContext'
import { indexPath } from 'components/paths'
import { removeLocaleFromPath } from 'functions/language'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { AppStaticContext } from 'components/components.types'

export const NotFoundSwitcher: FC<{ staticContext: AppStaticContext }> = ({
  staticContext,
}) => {
  const dispatch = useDispatch()
  const { pathname } = useShallowEqualSelector(
    ({
      router: {
        location: { pathname },
      },
    }) => ({ pathname })
  )
  const { baseUrl } = useLayoutContext()
  const { pathname: locationPathname } = useLocation()
  useEffect(() => {
    /**
     * NotFoundSwitcher не может дать правильно отрендерится на сервере
     * К этому моменту строка html уже сформирована и будет дальнейшее проксирование
     * на полную отрисовку 404-й
     * /activity/settings 200
     * /activity/settingsss 404
     */

    /**
     * Почему мы удаляем locale из baseUrl и pathname
     * Ниже мы сравниваем indexPath(путь const).
     * А сравнием мы с путями которые могут меняется зависимо
     * от авторизован ли пользователь или нет.
     * Поэтому если пользователь неавторизован нам нужно удалить
     * locale если она есть.
     */
    const baseUrlWithoutLocale = mergeAllUrls(removeLocaleFromPath(baseUrl))
    const pathnameWithoutLocale = mergeAllUrls(removeLocaleFromPath(pathname))

    if (
      locationPathname === pathname &&
      // indexPath см. https://redmine.mamba.ru/issues/106927 видимо как-то роутер
      // неправильно вычисляет
      // TODO возможно лишние условия
      pathnameWithoutLocale !== indexPath &&
      baseUrlWithoutLocale !== indexPath
    ) {
      /**
       * Проверяем на соотвествие, потому что есть рассинхрон с библиотеки
       * connected-react-router c react-router
       */
      dispatch(showNotFoundAction(true))
    }
  }, [locationPathname, pathname, baseUrl, dispatch])
  // console.log('NotFoundSwitcher render', {
  //   locationPathname,
  //   pathname,
  //   baseUrl,
  // })
  if (staticContext) {
    // Указываем, что нам нужна на сервере 404-я
    dispatch(setStatusCodeAction(404))
  }
  return null
}
