import React, { FC, useMemo } from 'react'
import { ThreeFeaturesLoadable } from './ThreeFeatures/ThreeFeaturesLoadable'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { isLoveMailru } from 'common/constants'
import { IndexStepEntry } from 'components/page/StepRegistration/IndexStepEntry'
import { useRedesign } from 'hooks/useRedesign'
import { IndexPageLoadable } from 'components/page/IndexPage/IndexPageLoadable'

export const LandingSwitcher: FC = () => {
  const { partnerId } = useShallowEqualSelector(
    ({ systemReducer: { partnerId } }) => ({
      partnerId,
    })
  )
  const redesign = useRedesign()

  const notLoveMailru = useMemo(() => !isLoveMailru(partnerId), [partnerId])

  if (redesign) {
    return <IndexPageLoadable />
  }

  if (notLoveMailru) {
    return <IndexStepEntry />
  }

  return <ThreeFeaturesLoadable />
}
