import React, { FC } from 'react'
import { CreateBaseUrl } from 'components/layout/MambaLayout/CreateContext'
import { landingLayoutContextId } from 'components/layout/MambaLayout/Context'
import { StepRegistrationLoadable } from 'components/page/StepRegistration/StepRegistrationLoadable'
import { useRouteMatch } from 'react-router'

export const IndexStepEntry: FC = () => {
  const { url, path } = useRouteMatch()

  return (
    <CreateBaseUrl
      uid="current-step-boarding"
      contextId={landingLayoutContextId}
      baseUrl={url}
      basePath={path}
    >
      <StepRegistrationLoadable />
    </CreateBaseUrl>
  )
}
